import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from "clsx";
import {
  Container, Box, Typography,
  TextField, Button, Grid, Paper,
  Snackbar, Stack, Alert as MuiAlert, ListItemAvatar, CssBaseline, AppBar, Toolbar, IconButton, Icon
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { styled, useTheme } from "@mui/material/styles";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIcon from "@mui/icons-material/Report";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon  from '@mui/icons-material/Menu';



const drawerWidth = 240;

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

export default function AdminPortal(props) {
  const drawerWidth = 240;
  // const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDrawerToggle = () =>  {
    console.log("clicked");
    console.log(isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
    console.log(isMenuOpen);
  }

  const shiftTopBarWidth = () => isMenuOpen ? `calc(100%) - ${drawerWidth}px` : "100%";

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column"
    }}>
      <CssBaseline/>
      <AppBar position='fixed'
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: () => shiftTopBarWidth,
          ml: `${drawerWidth}px`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <IconButton
          onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
        >
          { isMenuOpen ? <ChevronLeftIcon /> : <MenuIcon /> }
        </IconButton>
        <Toolbar sx={{ 
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
          }}>
          <Typography variant="h6" noWrap component="div">
            JKUSDA Sabbath School Admin Portal
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        position = 'fixed'
        anchor= "left"

        open={isMenuOpen}
        onClose={handleDrawerToggle}
        sx={{
          marginTop:'200px',
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
        }}
      >
        <AppBar position='fixed'/>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {isMenuOpen? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {/* <Toolbar /> */}
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
              <ListItem key={text} diablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p:3 }}>
        <Toolbar />

      </Box>
    </Box>
  );
}