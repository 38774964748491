import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Box, Typography,
  TextField, Button, Grid, Paper,
  Snackbar, Stack, Alert as MuiAlert
} from '@mui/material';

export default function Home(props) {
  const navigate = useNavigate();

  return (

    <Container component="main">
      <Box
        sx={{
          marginTop: "20vh",
        }}
      >
        <Grid container
          item
          xs={12}
          sm={12}
          md={10}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            margin: "auto"
          }}
        >
            <Box
              sx={{
                mx: 4,
                my: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography 
                component="h1" 
                variant="h5"
                sx = {{
                  text: "center"
                }}
              >
                Welcome To JKUSDA Sabbath School
              </Typography>
              <br/>
              <br/>
              <Typography component="h1" variant="h5">
                I am a
              </Typography>
            </Box>
        </Grid>
        <Grid container
          sx = {{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                mx: 4,
                my: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Student
              </Typography>
              <Box noValidate sx={{ mt: 1 }} >

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                 onClick={() => navigate('/student/register/')}
                >
                  Register
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                mx: 4,
                my: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Teacher
              </Typography>
              <Box noValidate sx={{ mt: 1 }} >

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                 onClick={() => navigate('/login')}
                >
                  Portal
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}