import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import DetailsBar from './DetailsBar';
import RegisterTabs from './RegistarBar';
import LoginPage from './LoginPage';
import AddStudent from './AddStudent';
import AddTeacher from './AddTeacher';
import AdminPortal from './AdminPortal';
import Home from './Home';
import { Routes, Route, Navigate, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    if (cookies.get('token') === undefined) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
    console.log(cookies.get('usertype'));
    console.log(cookies.get('token'));
    console.log(isLoggedIn);
    console.log(userType);
  }, [''])

  const handleLogin = (response) => {
    console.log(response);
    setIsLoggedIn(true);
  }

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Navigate to="/home" />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/app" element={
          cookies.get('token') !== 'undefined' && cookies.get('usertype') === "teacher" ? (
            <>
              <DetailsBar />
              <RegisterTabs />
            </>
          ) : (
            <Navigate to="/login" />
          )}
        />
        <Route path="/admin" element={
          cookies.get('token') !== 'undefined' && cookies.get('usertype') === "admin" ? (
            <AdminPortal />
          ) : (
            <AdminPortal />
            // <Navigate to="/login" />
          )}
        />
        <Route path="/login"
          element = {<LoginPage onLogin={setIsLoggedIn} setUserType={setUserType}/>}
        />
        <Route path="/student/register"
          element = {<AddStudent />}
        />
        <Route path="/teacher/register"
          element = {<AddTeacher />}
        />
        <Route path="*" element={ <Navigate to="/home"/> }/>
      </Routes>
    </div> 
  );
}

export default App;
