import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, IconButton,
        Typography, Menu, MenuItem, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getLessonDetails } from './db';

export default function DetailsBar(props) {

  const [lessonDetails, setLessonDetails] = useState([]);

  let lesson = '';

  const outer = {
    display: "flex",
    width: "100%"
  }

  const lessonTitle = {
    display: "flex",
    alignItems: "center",
  }

  const weeksTitle = {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "center",
    textAlign: "center"
  }

  useEffect(() => {
    getLessonDetails().then((res) => setLessonDetails(res.data));
    console.log(lessonDetails);
  }, ['']);

  return (
    <div className="details-bar"style={outer}>
      <Box>
        <AppBar>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <div style={outer}>
                <div style={weeksTitle}>
                  <div>
                    <Typography variant="h5">
                      Lesson {lessonDetails['lesson_number']}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      text="Add Student"
                    />
                  </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>
    </div>
  )
}
