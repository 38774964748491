import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Container, Box, Typography,
  TextField, Button, Grid, Paper,
  Snackbar, Stack, Alert as MuiAlert,
  FormControl, InputLabel, Select, MenuItem,
  CircularProgress,
} from '@mui/material';
import LessonImage from './cover.jpg';
import { createTeacher, getTeachers, teacherTokenConfirm } from './db.js';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function SelectOption(props) {
  const formOption = props.formOption;
  if (formOption.id == "classteacher" && !formOption.isReady) {
    return (
      <FormControl
      fullWidth
      margin="normal"
      required={formOption.required}
      >
      <InputLabel
        id={`${formOption.id}-label`}
      >
        <>
          <CircularProgress size={22}/>
          <>  Fetching Teachers ...</>
        </>
      </InputLabel>
      <Select
        labelId={`${formOption.id}-label`}
        id={formOption.id}
        value={formOption.value}
        label={formOption.label}
        onChange={(e) => { console.log(e.target.value); formOption.setter(e.target.value) }}
        disabled={true}
        inputProps={{
          required: formOption.required
        }}
        sx={{
          '& .MuiSelect-select.MuiSelect-select': {
            textAlign: 'left',
          },
          '& .MuiSelect-select.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiSelect-root': {
            paddingLeft: '8px',
          },
        }}
      >
        {/* {
          formOption.choices.map((choice) => (
            <MenuItem value={choice}>{choice.name}</MenuItem>
          ))
        } */}
      </Select>
      </FormControl>
    )
  }
  return (
    <FormControl
      fullWidth
      margin="normal"
      required={formOption.required}
    >
      <InputLabel
        id={`${formOption.id}-label`}
      >
        {formOption.label}
      </InputLabel>
      <Select
        labelId={`${formOption.id}-label`}
        id={formOption.id}
        value={formOption.value}
        label={formOption.label}
        onChange={(e) => { console.log(e.target.value); formOption.setter(e.target.value) }}
        // inputProps={{
          // required: formOption.required
        // }}
        sx={{
          '& .MuiSelect-select.MuiSelect-select': {
            textAlign: 'left',
          },
          '& .MuiSelect-select.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiSelect-root': {
            paddingLeft: '8px',
          },
        }}
      >
        {
          formOption.choices.map((choice) => (
            <MenuItem value={choice}>{choice.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default function AddTeacher(props) {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [gender, setGender] = useState('');
  const [yearGroup, setYearGroup] = useState('');
  const [course, setCourse] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [classTeacher, setClassTeacher] = useState('');
  const [snackBar, setSnackBar] = useState({});
  const [teacherChoices, setTeacherChoices] = useState([]);
  const [yearGroupChoices, setYearGroupChoices] = useState([]);
  const [genderChoices, setGenderChoices] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isTokenConfirmed, setIsTokenConfirmed] = useState(false);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenConfirmed) {
      getTeachers().then((res) => {
        setIsReady(true);
        console.log(res.data);
        console.log(typeof res.data);
        setTeacherChoices(res.data);
      }).catch((err) => {
        setSnackBar({ open: true, message: 'Network error while fetching teachers. Please refresh or contact admin', severity: 'error' });
        setIsReady(false);
      });
    }
    setYearGroupChoices([
      {
        "name": "Gershonite",
        "value": "2022"
      },
      {
        "name": "Kohathite",
        "value": "2021"
      },
      {
        "name": "Shilonite",
        "value": "2020"
      },
      {
        "name": "Kennite",
        "value": "2019"
      },
      {
        "name": "Waldense",
        "value": "2018"
      },
      {
        "name": "Ziphonite",
        "value": "2017"
      },
      {
        "name": "Associate",
        "value": "-1"
      },
    ]);
    setGenderChoices([
      {
        'name': 'Male',
        'value': 'M'
      },
      {
        'name': 'Female',
        'value': 'F'
      },
    ]);
  }, [''])

  const formInputs = [
    {
      id: "username",
      label: "User Name",
      name: "username",
      // autocomplete: "username",
      setter: setUsername,
      value: username,
      required: true
    },
    {
      id: "firstname",
      label: "First Name",
      name: "firstname",
      // autocomplete: "firstname",
      setter: setFirstName,
      value: firstName,
      required: true
    },
    {
      id: "middlename",
      label: "Middle Name",
      name: "middlename",
      // autocomplete: "middlename",
      setter: setMiddleName,
      value: middleName,
      required: false
    },
    {
      id: "lastname",
      label: "Last Name",
      name: "lastname",
      // autocomplete: "lastname",
      setter: setLastName,
      value: lastName,
      required: true
    },
    {
      id: "phonenumber",
      label: "Phone Number",
      name: "phonenumber",
      // autocomplete: "phonenumber",
      setter: setPhoneNumber,
      value: phoneNumber,
      required: true
    },
    {
      id: "email",
      label: "Email",
      // autocomplete: "email",
      type: "email",
      setter: setEmail,
      value: email,
      required: true
    },
    {
      id: "course",
      label: "Course",
      name: "course",
      // autocomplete: "course",
      setter: setCourse,
      value: course,
      required: false
    },
    {
      id: "regno",
      label: "Registration Number / ID No",
      name: "regno",
      // autocomplete: "regno",
      setter: setRegistrationNumber,
      value: registrationNumber,
      required: true
    },
    {
        id: "password",
        label: "Password",
        name: "password1",
        // autocomplete: "password",
        type: "password",
        setter: setPassword1,
        value: password1,
        required: true
    },
    {
        id: "password",
        label: "Confirm Password",
        name: "password2",
        // autocomplete: "password",
        type: "password",
        setter: setPassword2,
        value: password2,
        required: true
    }
  ];

  const formOptions = [
    {
      id: "gender",
      label: "Gender",
      name: "gender",
      // autocomplete: "gender",
      setter: setGender,
      value: gender,
      choices: genderChoices,
      required: true
    },
    {
      id: "yeargroup",
      label: "Year Group",
      name: "yeargroup",
      // autocomplete: "yeargroup",
      setter: setYearGroup,
      value: yearGroup,
      choices: yearGroupChoices,
      required: true
    },
    {
      id: "classteacher",
      label: "Co-Teacher",
      name: "classteacher",
      // autocomplete: "classteacher",
      isReady: isReady,
      setter: setClassTeacher,
      value: classTeacher,
      choices: teacherChoices,
      required: false
    }
  ];

  const handleSubmit = () => {
    setIsButtonDisabled(true)
    formInputs.map(input => {
      console.log(input.value);
    })
    formOptions.map(option => {
      console.log(option.value)
    })

    const newTeacher = {
      "username": username,
      "first_name": firstName,
      "middle_name": middleName,
      "last_name": lastName,
      "password": password1,
      "password2": password2,
      "phone_number": phoneNumber,
      "email": email,
      "reg_no": registrationNumber,
      "course": course,
      "gender": gender.value,
      "year_group": yearGroup.value,
      "class_id": classTeacher.class_id
    }

    if (!firstName || !lastName || !phoneNumber || !email || !course || !gender || !yearGroup) {
      setSnackBar({ open: true, message: 'Please fill in all the required fields.', severity: 'error' });
      setIsButtonDisabled(false)
      return;
    }

    if (!password1 || password1 !== password2)
    {
        setSnackBar({ open: true, message: "Passwords don't match", severity: 'error' });
        setIsButtonDisabled(false)
        return;
    }

    console.log(newTeacher)

    createTeacher(newTeacher).then((res) => {
      setIsButtonDisabled(false)
      if (res.status === 200) {
        setSnackBar({ open: true, message: 'Teacher successfully created', severity: 'success' }); 
        navigate('/login');
        console.log(res);
      }
    }).catch((error) => {
      setIsButtonDisabled(false); 
      setIsReady(true);
      console.log(error)
      setSnackBar({ open: true, message: error.response.data.message, severity: 'error' }); 
    })
  }

  const submitTokenConfirmation = (token) => {
    teacherTokenConfirm({'token': token}).then((res) => {
      setIsTokenConfirmed(true);
      setSnackBar({ open: true, message: res.data.message, severity: 'success'});
      getTeachers().then((res) => {
        setIsReady(true);
        console.log(res.data);
        console.log(typeof res.data);
        setTeacherChoices(res.data);
      }).catch((err) => {
        setSnackBar({ open: true, message: 'Network error while fetching teachers. Please refresh or contact admin', severity: 'error' });
        setIsReady(false);
      });
    }).catch((error) => {
      setSnackBar({open: true, message: error.response.data.message, severity: 'error'});
    })
  }  

  return (
    <Container component="main">
      <Box
        sx={{
          margin: "5vh 0",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${LessonImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          {
            isTokenConfirmed ? (
              <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  mx: 4,
                  my: 6,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  Create Teacher
                </Typography>
                <Box noValidate sx={{ mt: 1 }} >
                  {
                    formInputs.map(input => {
                      return (
                        <TextField
                          margin="normal"
                          required={input.required}
                          fullWidth
                          id={input.id}
                          label={input.label}
                          name={input.name}
                          type = {input.type}
                          // // autocomplete={input.autocomplete}
                          onChange={(e) => input.setter(e.target.value)}
                          inputProps={{
                            required: formInputs.required
                          }}
                        />
                      );
                    })
                  }
                  {
                    formOptions.map(formOption => (
                      <SelectOption formOption={formOption} />
                    ))
                  }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled || !isReady}
                  >
                    {
                      isButtonDisabled ? (
                        <>
                        <CircularProgress size={22}/>
                        <>Registering ...</>
                        </>
                      ) : (
                        <>Register</>
                      )
                    }
                  </Button>
                </Box>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <Snackbar
                    open={snackBar.open}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setSnackBar({ ...snackBar, open: false })}
                  >
                    <Alert
                      severity={snackBar.severity}
                      sx={{ width: '100%' }}
                    >
                      {snackBar.message}
                    </Alert>
                  </Snackbar>
                </Stack>
              </Box>
            </Grid>
              ) : (
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
              >
                <Box
                  sx={{
                    mx: 4,
                    my: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h1" variant="h5">
                    Please Enter Token Provided by Admin
                  </Typography>
                  <Box noValidate sx={{ mt: 1 }} >
                          <TextField
                            margin="normal"
                            fullWidth
                            id="token-confirm"
                            label="Confirm Token"
                            name="Confirm Token"
                            type = "text"
                            onChange={(e) => setToken(e.target.value)}
                          />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={() => submitTokenConfirmation(token)}
                        > Confirm Token</Button>
                  </Box>
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar
                      open={snackBar.open}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      onClose={() => setSnackBar({ ...snackBar, open: false })}
                    >
                      <Alert
                        severity={snackBar.severity}
                        sx={{ width: '100%' }}
                      >
                        {snackBar.message}
                      </Alert>
                    </Snackbar>
                  </Stack>
                </Box>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Container>
  )
}