import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Box, Typography,
         TextField, Button, Grid, Paper,
         Snackbar, Stack, Alert as MuiAlert,
         CircularProgress } from '@mui/material';
import LessonImage from './cover.jpg';
import { login } from './db.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function LoginPage(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackBar, setSnackBar] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsButtonDisabled(true)
    login(username, password).then(
      (response) => {
        setIsButtonDisabled(false)
        if (response.status === 200) {
          setSnackBar({
            open: true,
            message: 'Success Login!',
            severity: 'success',
          });
          setTimeout(() => {
            props.onLogin(true);
            props.setUserType(response.data.type);
            if (response.data.type === 'teacher') {
              navigate('/app');
              cookies.set('usertype', 'teacher')
            } else if (response.data.type === 'admin') {
              cookies.set('usertype', 'admin');
              navigate('/admin');
            }
          }, 1500);
        } else {
          const err = response.data.non_field_errors;
          setSnackBar({
            open: true,
            message: err,
            severity: 'error',
          });;
        }
      }
    );
  }
  return(
     <Container component="main">
        <Box
          sx={{
            marginTop: "20vh",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${LessonImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx = {{
                mx: 4,
                my: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box noValidate sx={{ mt: 1}} >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  autoFocus
                  autocomplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  {
                    isButtonDisabled ? (
                      <>
                      <CircularProgress size={22}/>
                      <>Signing in ...</>
                      </>
                    ) : (
                      <>Sign In</>
                    )
                  }
                </Button>
              </Box>
              <Grid container>
                <Grid item
                  sx = {{
                    marginTop: "10px",
                    alignItems: "left",
                    display: "flex",
                    // justifyContent: "flex-end",
                  }}
                >
                  <Link variant="body2" to="../teacher/register">
                    Not registered as a teacher?
                  </Link>
                </Grid>
              </Grid>
              <Stack spacing={2} sx={{ width: '100%'}}>
                <Snackbar
                  open={snackBar.open}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={() => setSnackBar({...snackBar, open: false})}
                >
                  <Alert
                    severity={snackBar.severity}
                    sx={{ width: '100%' }}
                  >
                    {snackBar.message}
                  </Alert>
                </Snackbar>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
