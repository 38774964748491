import React, { useState, useEffect, useReducer } from 'react';
import { Tabs, Tab, Typography, Box,
         FormControl, FormGroup, Checkbox,
         Badge, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { login, getStudentsForClass, submitStudents } from './db';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labeldby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && ( 
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function allPropsFor(index) {
  return {
    id: `siple-tab-${index}`,
    'aria-contols': `simple-tabpanel-${index}`,
  };
}


function customBadge(backgroundcolor, color, text, count) {
  return (
    <Badge badgeContent={count[text]}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: backgroundcolor,
          color: color
          }
        }}
    >
      {text}
    </Badge>
  )
}


export default function RegisterTabs(props) {
  const [value, setValue] = useState(0);
  const [students, setStudents] = useState([]);
  const [tabCount, setTabCount] = useState({"Unmarked": 0, "Selected": 0, "Submitted": 0});
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  }

  // add selected field for toggling between umarked and selected
  const addSelected = (res) => {
    return res.map((student) => {
      return (
        student.submitted === true ?
          {...student, selected: true} : {...student, selected: false}
      )
    })
  }

  const countSelection = (selected, submitted) => {
    return students.reduce((count, student) => {
      if (student.selected === selected && student.submitted === submitted) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    console.log("getting students...");
    getStudentsForClass().then(
      (res) => {
        setStudents(() => addSelected(res));
        console.log(students)
      });

    const topbar = document.querySelector('.details-bar');
    const topbarHeight = topbar ? topbar.offsetHeight : 0;
    document.documentElement.style.setProperty('--topbar-height', `${topbarHeight}px`);
  }, ['']);

  useEffect(() => {
    if (students.length > 0) {
      setTabCount({
        "Unmarked": countSelection(false, false),
        "Selected": countSelection(true, false),
        "Submitted": countSelection(true, true)
        });
    }
  }, [students]);

  const handleSelectStudent = (selectedStudent) => {
    const updatedStudents = students.map((student) => {
      if (student.class_member_id == selectedStudent.class_member_id) {
        console.log(student);
        setTabCount((prevCount) => ({
          ...prevCount,
          ["Unmarked"]: prevCount["Unmarked"] - 1,
          ["Selected"]: prevCount["Selected"] + 1,
        }));
        return {...student, selected: true};
        console.log(student);
      }
      return student;
    });

    setStudents(updatedStudents);
  }

  const handleUnselectStudent = (selectedStudent) => {
    const updatedStudents = students.map((student) => {
      if (student.class_member_id == selectedStudent.class_member_id) {
        console.log(student);
        setTabCount((prevCount) => ({
          ...prevCount,
          ["Unmarked"]: prevCount["Unmarked"] + 1,
          ["Selected"]: prevCount["Selected"] - 1,
        }));

        return {...student, selected: false};
        console.log(student);
      }
      return student;
    });

    setStudents(updatedStudents);
  }


  const mergeArrays = (arrayTo, arrayFrom) => {
    return arrayTo.map(obj => {
      const similarObj = arrayFrom.find(obj2 => obj2.class_member_id === obj.class_member_id);
      return similarObj ? similarObj : obj;
    });
  }

  const submitSelectedStudents = () => {
    const selectedStudents = students.filter(
        (student) => student.selected === true && student.submitted === false).map(
        (student) => ({...student, attendance: true, submitted: true})
      );
    const data = {
      // 'lesson_no': '11',
      'students': selectedStudents,
    };

    console.log(selectedStudents);

    submitStudents(data).then(
      (response) => {
        if (response.status === 200){
          setStudents(mergeArrays(students, selectedStudents));
        } else {
          console.log(response.data)
        }
      }).catch(error => {
        console.error(error)
      });
    
  }


  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }

  const flexCenter = {
    display: "flex",
    justifyContent: "center",
    background: "white",
    zIndex: 1
  }

  return (
  <div style={centerStyle}>
    <Box>
      <Box className="tabDiv" sx={{ borderBottom: 1, borderColor: 'divider'}} style={flexCenter}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={customBadge('red', 'white', "Unmarked", tabCount)}
              {...allPropsFor(0)}/>
          <Tab label={customBadge('orange', 'white', "Selected", tabCount)}
              {...allPropsFor(1)}/>
          <Tab label={customBadge('green', 'white', "Submitted", tabCount)}
              {...allPropsFor(2)}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FormControl>
        {
          Array.from(students).filter((student) => {
            return (student.selected === false);
          }).map((student, index) => {
            return (
              <FormGroup style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                {student.name}
                <Checkbox checked={student.selected} onChange={() => handleSelectStudent(student)}/>
              </FormGroup>
            )
          })
        }
        </FormControl>

      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormControl>
        {
          Array.from(students).filter((student) => {
            return (student.selected === true && student.submitted === false);
          }).map((student, index) => {
            return (
              <FormGroup style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                {student.name}
                <Checkbox defaultChecked indeterminate="true" onChange={() => handleUnselectStudent(student)}/>
              </FormGroup>
            )
          })
        }
          <Button onClick={submitSelectedStudents} variant="contained">Submit</Button>
        </FormControl>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormControl>
        {
          Array.from(students).filter((student) => {
            return (student.submitted === true);
          }).map((student, index) => {
            return (
              <FormGroup style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                {student.name}
                <Checkbox checked disabled/>
              </FormGroup>
            )
          })
        }
        </FormControl>
      </TabPanel>
    </Box>
  </div>
  )
}
