import axios from 'axios';
import Cookies from 'universal-cookie';
import { config } from './constants';


// const baseURL = "http://13.213.42.246/api/v1"
const baseURL = config.urls.baseUrl;
// const baseURL = "https://backend.portal.jkusdachurch.org/lessonTeachers"

const cookies = new Cookies();

export async function login (user, pass) {
  return axios.post(
    `${baseURL}/teacher/login/`,
      {
        username: user,
        password: pass
      }
    ).then((response) => {
      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('token_expiry', response.data.expiry,{path: '/'});
      cookies.set('user', user);
      cookies.set('user_type', response.data.type, { path: '/' });
      console.log(response);
      console.log(cookies.get('token') || "None");
      return response;
    }).catch(error => {
      cookies.set('token', '');
      console.error(error.response);
      return error.response;
    });
}

export async function getStudentsForClass() {
  const students = await axios.get(
    `${baseURL}/teacher/getstudents/`,
    {
      headers: {
        "Authorization": `Token ${cookies.get('token')}`
      }
    }
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(err => console.error(err));

  return students;
}

export async function submitStudents(data) {
  return axios.post(
    `${baseURL}/teacher/mark_attendance/`,
    data,
    {
      headers: {
        "Authorization": `Token ${cookies.get('token')}`
      }
    }
  )
}

export async function createStudent(student) {
  return axios.post(
    `${baseURL}/student/create/`,
    student
  )
}

export async function createTeacher(teacher) {
  return axios.post(
    `${baseURL}/teacher/register/`,
    teacher
  )
}

export async function getTeachers() {
  return axios.get(
    `${baseURL}/teachers/`
  )
}

export async function getLessonDetails() {
  return axios.get(
    `${baseURL}/lessons/current/details`
  )
}

export async function teacherTokenConfirm(data) {
  return axios.post(
    `${baseURL}/teacher/token_confirm/`,
    data
  )
}